window.app.factory('ActivityReportFactory', ['DBFactory', 'AuthService', 'TimeTrackingFactory', 'AssignmentFactory', '$filter', 'ErrFactory', 'API',
                                    function (DBFactory,   AuthService,   TimeTrackingFactory,   AssignmentFactory,   $filter,   ErrFactory,   API) {
  class ActivityReport {
    id:              number;
    user_id:         number;

    startDate:       Date;
    endDate:         Date;

    assignment:      any;
    dailyReportList: any[];

    messageTempton:  string;
    reportStatus:    any;
    signature:       string;
    signer:          string;
    attachment:      string;

    useTimestamps:   number;
    mileage_money:   boolean;
    readyToSubmit:   boolean;

    haveHolidays:    boolean;

    errors: any[];
    customer_signature_confirmation_email: any[];

    static COLECTION_NAME = 'activity_reports';
    constructor (data) {
      this.id              = data.id      || null;
      this.user_id         = data.user_id || AuthService.userId || null;

      this.startDate       = TimeTrackingFactory.parseDateTime(data.startDate || data.start_date);
      this.endDate         = TimeTrackingFactory.parseDateTime(data.endDate   || data.end_date);

      this.assignment      = data.assignment || data.job ? new AssignmentFactory(data.assignment || data.job) : null;

      // this.dailyReportList = data.dailyReportList && data.dailyReportList.map((dr) => new TimeTrackingFactory.DailyNew(Object.assign(dr, { assignment: data.assignment || data.job || null}))) || [];
      this.dailyReportList = data.dailyReportList && data.dailyReportList.length ? data.dailyReportList.map((dr) => new TimeTrackingFactory(Object.assign(dr, { assignment: data.assignment || data.job || null }))) : [];

      this.messageTempton  = data.messageTempton || data.external_employee_notes || null;
      this.reportStatus    = data.reportStatus   || this.getStatus(data.status)  || null;
      this.signature       = data.signature      || data.customer_signature      || null;
      this.signer          = data.signer         || null;
      this.attachment      = data.attachment     || null;

      this.useTimestamps   = data.useTimestamps;
      this.mileage_money   = data.mileage_money || false;
      this.readyToSubmit   = data.readyToSubmit || false;

      this.haveHolidays    = !!this.dailyReportList.find(tf => tf.holidays.length);

      this.customer_signature_confirmation_email = data.customer_signature_confirmation_email || [];
    }

    private getStatus(status) {
      switch (+status) {
        case 1:
          return { status: 1, label: 'timeTrackings.releaseNow' };
        case 2:
          return { status: 2, label: 'timeTrackings.releaseLater' };
        case 2:
          return { status: 3, label: 'timeTrackings.releaseNeedClarification' };
        default:
          return null;
      }
    }

    get totalWorkingTime(): number {
      return this.dailyReportList.reduce((sum,p) => sum + p.duration, 0);
    }

    get workingTimeNoPauses(): number {
      return this.dailyReportList.reduce((sum,p) => sum + p.totalDurationTime, 0);
    }

    toJSON () {
      return Object.assign({}, {
        id:              this.id,
        user_id:         this.user_id,

        startDate:       this.startDate instanceof Date ? this.startDate.toISOString() : null,
        endDate:         this.endDate   instanceof Date ? this.endDate.toISOString()   : null,

        assignment_id:   this.assignment ? this.assignment.id : null,
        dailyReportList: this.dailyReportList.map((dr) => dr.toJSON()),

        messageTempton:  this.messageTempton,
        reportStatus:    this.reportStatus,
        signature:       this.signature,
        signer:          this.signer,
        attachment:      this.attachment,

        readyToSubmit:   this.readyToSubmit || false,
        mileage_money:   this.mileage_money || false,
        useTimestamps:   this.useTimestamps,

        customer_signature_confirmation_email: this.customer_signature_confirmation_email || []
      });
    }

    toSubmitFormat() {
      return Object.assign({}, {
        submitted_at:            new Date().toISOString(),

        assignment_id:           this.assignment && this.assignment.id,
        start_date:              this.startDate instanceof Date ? $filter('date')(this.startDate, 'yyyy-MM-dd') : null,
        end_date:                this.endDate   instanceof Date ? $filter('date')(this.endDate,   'yyyy-MM-dd') : null,

        time_frames:             this.dailyReportList && this.dailyReportList.length ? this.dailyReportList.map((dr) => dr.toSubmitData()) : null,

        external_employee_notes: this.messageTempton,
        status:                  this.reportStatus.status,

        customer_signature:      this.signature,
        signer:                  this.signer,
        attachment:              this.attachment,

        mileage_money:           this.mileage_money,
        submit_with_timestamp:   this.useTimestamps ? 1 : 0,

        customer_signature_confirmation_email: this.customer_signature_confirmation_email.length ? this.customer_signature_confirmation_email.map(ce => ce.email) : null,
        customer_signature_confirmation:     !!this.customer_signature_confirmation_email.length
      });
    }

    submit() {
      return ActivityReport.submit([this]);
    }

    save(tableName: string = null) {
      let data = this.toJSON();
      if (!data.id) delete data.id;

      return DBFactory.then((ds) => ds.db)
      .then((db) => db.put(tableName || ActivityReport.COLECTION_NAME, data))
      .then((id) => this.id = id)
      .then(() => this);
    }

    remove() {
      return Promise.all(this.dailyReportList.map((dr) => dr.remove()))
      .then(() => DBFactory.then((ds) => ds.db))
      .then((db) => db.remove(ActivityReport.COLECTION_NAME, this.id))
      .catch((err) => {
        console.error(err);
        throw new ErrFactory.ErrLocalDB;
      });
    }

    static getOwn () {
      let user_id = AuthService.userId;
      if (user_id) {
        let key  = ydn.db.KeyRange.only(user_id);
        return DBFactory.then((ds) => ds.db)
        .then((db) => db.valuesByIndex(ActivityReport.COLECTION_NAME, 'user_id', key, 2000))
        .then((list) => Promise.all(
          list.filter((item) => item.assignment_id || item.job_id)
          .map((item) => this.getByDbItem(item))
        ));
      }
      else return Promise.resolve([]);
    }

    static getById (id) {
      return DBFactory.then((ds) => ds.db)
      .then((db) => db.get(ActivityReport.COLECTION_NAME,id))
      .then((item) => this.getByDbItem(item));
    }

    static getByDbItem (dbData) {
      return AssignmentFactory.getById(dbData.assignment_id || dbData.job_id)
      .then((assignment) => new this(Object.assign(dbData, {assignment})));
    }

    static submit (list) {
      return Promise.resolve(API.TimeTracking.sendActivityReports({ working_periods: list.map((item) => item.toSubmitFormat()) }).$promise)
      .then((res) => {
        if (res.find(r => !r.success)) return Promise.reject(new ErrFactory(res.find(r => !r.success).errors.join('\n')));
        let data = res.map(r => r.working_period);
        return data;
      });
    }
  }

  class SubmittedActivityReport extends ActivityReport {
    created_by:              any;
    created_at:              Date;
    approved_at:             Date;
    customer_reviewed_at:    Date;

    corrected_time_frames:   any[];
    original_time_frames:    any[];

    internal_employee_notes: string;
    external_employee:       any;

    split_child_id:          number;
    split_parent_id:         number;

    reportCorrected:         boolean;
    createdByTempton:        boolean;
    mileageAvailable:        boolean;

    signature_confirmation:  any;
    mileage_money_report: any;
    constructor(data) {
      super(data);
      this.created_by              = data.created_by || null;
      this.created_at              = TimeTrackingFactory.parseDateTime(data.created_at);
      this.approved_at             = TimeTrackingFactory.parseDateTime(data.approved_at);
      this.customer_reviewed_at    = TimeTrackingFactory.parseDateTime(data.customer_reviewed_at);

      this.original_time_frames    = data.original_time_frames  || null;
      this.corrected_time_frames   = data.corrected_time_frames || null;

      this.internal_employee_notes = data.internal_employee_notes;
      this.external_employee       = data.external_employee;

      this.split_child_id          = data.split_child_id;
      this.split_parent_id         = data.split_parent_id;

      this.reportCorrected         = data.reportCorrected  || !!this.checkReportCorrected();
      this.createdByTempton        = data.createdByTempton || this.created_by?.id !== data.external_employee?.id;
      this.mileageAvailable        = data.mileageAvailable || data.mileage_money_report || data.mileage_money_report_id || this.dailyReportList.find(tf => tf.mileage_money_report);

      this.signature_confirmation  = data.signature_confirmation || null;
      this.mileage_money_report    = data.mileage_money_report;
    }

    static async loadFromServer (page, column, dir) {
      let res  = await Promise.resolve(API.workingPeriodsPaginated(page, column, dir));
      let data = {
        working_periods: await Promise.all(res.working_periods.map((data) => this.fromAPIData(data))),
        meta: res.meta
      };
      return data;
    }

    static async loadWorkingPeriodById(id) {
      let res  = await Promise.resolve(API.workingPeriodById(id));
      let data = res && res.working_period && await this.fromAPIData(res.working_period);
      if (!data) return Promise.reject();
      return data;
    }

    static async fromAPIData (data) {
      let time_frames = data.corrected_time_frames.length > 0 ? data.corrected_time_frames : data.original_time_frames;
      data.corrected_time_frames = data.corrected_time_frames;
      data.original_time_frames = data.original_time_frames;
      if (time_frames.length > 0) {
        data.dailyReportList = time_frames.map((tt) => {
          let daily: any = {
            id: tt.id,
            original_time_frame_id: tt.original_time_frame_id,
            start_date: tt.started_at,
            end_date: tt.ended_at,
            pauses: tt.pauses,
            project: tt.activity,
            holidays: tt.holidays
          }

          let frame = daily.original_time_frame_id && data.original_time_frames.find(item => item.id === daily.original_time_frame_id);

          if (frame) {
            daily.start_date_origin = frame.started_at;
            daily.end_date_origin = frame.ended_at;
            daily.pauses = daily.pauses.map(pause => {
              let originPause = frame.pauses.find(framePause => framePause.id === pause.id);
              pause.start_origin = originPause && originPause.start;
              pause.end_origin = originPause && originPause.end;
              return pause;
            });
          }
          return daily;
        });
      }
      if (data.attachment_url) data.attachment = data.attachment_url;

      data.startDate = TimeTrackingFactory.parseDateTime(data.startDate || data.start_date, true);
      data.endDate   = TimeTrackingFactory.parseDateTime(data.endDate   || data.end_date,   false, true);

      if (data.assignment && !(data.assignment instanceof AssignmentFactory)) {
        data.assignment = new AssignmentFactory(Object.assign({},{
          external_employee: data.external_employee,
          customer: data.customer
        }, data.assignment));
      }

      return await Promise.resolve(new this(data));
    }

    private checkReportCorrected() {
      if (!this.approved_at) return false;
      if (this.customer_reviewed_at) return true;
      if (!this.attachment && this.corrected_time_frames && this.corrected_time_frames.length) {
        let newFrames = this.corrected_time_frames.find(dr => !dr.original_time_frame_id);
        let deletedFrames = this.original_time_frames.find(otf => !this.corrected_time_frames.find(ctf => otf.id === ctf.original_time_frame_id));
        let newPause = this.original_time_frames.find(otf => {
          let ctf_p = this.corrected_time_frames.find(ctf => ctf.original_time_frame_id === otf.id);
          if (!ctf_p) return false;
          return ctf_p.pauses.length !== otf.pauses.length || 
                 ctf_p.pauses.find(p => !otf.pauses.find(otf_p => otf_p.id === p.id));
        })
        let timesCorrected = this.corrected_time_frames.find(tf => tf.corrected || tf.pauses.find(p => p.corrected));
        return newFrames || deletedFrames || timesCorrected || newPause;
      }
      return false;
    }

    toJSON () {
      return Object.assign({}, super.toJSON(), {
        external_employee:       this.external_employee ? this.external_employee : null,
        created_by:              this.created_by        ? this.created_by        : null,
        created_at:              this.created_at           instanceof Date ? this.startDate.toISOString()            : null,
        approved_at:             this.approved_at          instanceof Date ? this.approved_at.toISOString()          : null,
        customer_reviewed_at:    this.customer_reviewed_at instanceof Date ? this.customer_reviewed_at.toISOString() : null,

        original_time_frames:    this.original_time_frames,
        corrected_time_frames:   this.corrected_time_frames,

        internal_employee_notes: this.internal_employee_notes,

        split_child_id:          this.split_child_id,
        split_parent_id:         this.split_parent_id,

        reportCorrected:         this.reportCorrected,
        createdByTempton:        this.createdByTempton,
        mileageAvailable:        this.mileageAvailable,

        signature_confirmation:  this.signature_confirmation,
      });
    }
  }

  Object.defineProperty(ActivityReport, 'statusList', { value: [
    { status: 1, label: 'timeTrackings.releaseNow' },
    { status: 2, label: 'timeTrackings.releaseLater' },
    { status: 3, label: 'timeTrackings.releaseNeedClarification' }
  ] });

  Object.defineProperty(ActivityReport, 'SubmittedActivityReport', { value: SubmittedActivityReport });
  return ActivityReport;
}]);
