window.app.factory('Config', ['defaultApi', 'API_PATH', 'ELASTIC_SEARCH',
  function(defaultApi, API_PATH, ELASTIC_SEARCH) {

  let self = this;
  Object.defineProperty(this, 'hostName', {
    set (hostName) {
      localStorage.setItem('customApi',hostName);
    },

    get () {
      return localStorage.getItem('customApi') || defaultApi;
    }
  });

  Object.defineProperty(this, 'location', {
    set (location) {
      localStorage.setItem('user_location', location);
    },

    get () {
      return localStorage.getItem('user_location') || '';
    }
  });

  Object.defineProperty(this, 'twoFactorToken', {
    set (token) {
      localStorage.setItem('two_factor_authentication_access_token', token);
    },

    get () {
      return localStorage.getItem('two_factor_authentication_access_token') || '';
    }
  });

  Object.defineProperty(this, 'host', {
    get () {
      return app.AM.hosts[this.hostName];
    }
  });
  Object.defineProperty(this, 'hostApi', {
    get () {
      return `${this.host}${API_PATH || '/api/v1/'}`;
    }
  });

  this.api = {
    elasticsearch: {
      get path() { return ELASTIC_SEARCH.path },
      get credentials() { return ELASTIC_SEARCH.credentials }
    }
  };

  return this;
}]);
