/**
 * @ngdoc Factory
 * @name AppFactory
 * @description App factory
 */
window.app.factory('AppFactory', ['$location', '$translate', '$route', 'Notification', 'ErrFactory', 'SplashLoaderFactory', 'SidebarFactory', 'INDEX_URL', '$rootScope', 'API', 'appVersion',
                          function($location,   $translate,   $route,   Notification,   ErrFactory,   SplashLoaderFactory,   SidebarFactory,   INDEX_URL,   $rootScope,   API,   appVersion) {
  var self = this;

  var title = 'myTempton';
  var templateHeader = '';
  var isDisableSwipe = false;

  self.disableSwipe = function () {
    isDisableSwipe = true;
  };

  self.enableSwipe = function () {
    isDisableSwipe = false;
  };

  self.isDisableSwipe = function () {
    return isDisableSwipe;
  };

  /**
   * Set title page
   * @param {[type]} _title
   */
  self.setTitle = function (_title) {
    title = _title || 'myTempton';
  };

  /**
   * Get title page
   * @return {string} title
   */
  self.getTitle = function () {
    return title;
  };

  /**
   * Check preview version
   * @return {Boolean}
   */
  self.isPreviewVersion = function () {
    return !!parseUrlData().preview;
  };

  self.isNetwork = function () {
    if (navigator.onLine != undefined) {
      return navigator.onLine;
    }

    if (navigator.connection && navigator.connection.type == Connection.NONE) {
      return false;
    }

    return true;
  };

  self.checkNetwork = function () {
    return new Promise((res,rej) => {
      if (this.isNetwork()) return res();
      else rej(new ErrFactory.NoInternetConnectivity);
    })
  }

  /**
   * Set template for header by type
   * @param {object} route
   */
  self.setTemplateHeader = function (route) {
    var templateHeaderUrl = route.templateHeaderUrl

    if (self.isPreviewVersion() && templateHeaderUrl) {
      templateHeaderUrl = 'views/headers/only_title.jade';
    }

    templateHeader = templateHeaderUrl;
  };

  /**
   * Get template header
   */
  self.getTemplateHeader = function () {
    return templateHeader;
  };

  self.networkProblem = function (callback) {
    var callback = callback || function () {};

    $translate([
      'errors.noInternetConnection',
      'errors.youHaveNoConnection'
    ]).then(function (translation) {
      Notification.alert({
        title: translation['errors.noInternetConnection'],
        desc: translation['errors.youHaveNoConnection']
      }, callback);
    });

  };

  /**
   * Go back page
   */
  self.goBack = function () {
    if (SplashLoaderFactory.isShow()) return;
    if (self.isPreviewVersion()) return self.notAvailablePreview();
    if (angular.element(document.body).hasClass('overlay-in')) return;
    $rootScope.readOnly = false;

    var ctrl = $route.current.$$route.controller;

    if ($location.path() == INDEX_URL || ctrl === 'SignInCtrl') {
      self.exit();
    } else {
      if (window.history && window.history.back) {
        window.history.back();
      } else {
        navigator.app.backHistory();
      }
    }
  };

  self.exit = function () {
    if (navigator.app && navigator.app.exitApp) {
      navigator.app.exitApp();
    } else if (navigator.device && navigator.device.exitApp) {
      navigator.device.exitApp();
    }else{
      $location.path(INDEX_URL);
    }
  };

  self.showSiteBar = function(){
    if ($rootScope.calendar && $rootScope.calendar.isCalendarActive) return;
    SidebarFactory.show();
  };

  var parseUrlData = function () {
    var params: any = window.location.href.substr(window.location.href.indexOf("?") + 1);
    var result: any = {};
    params = params.split("&");

    _.each(params, function (val, key) {
      let param = val.split("=");
      result[param[0]] = param[1];
    });

    return result;
  };

  self.checkNewVersion = function() {
    if (deviceIsIOS || !this.isNetwork()) return Promise.resolve();
    let platform = deviceIsIOS ? 'ios' : 'android';
    let appName = deviceIsIOS ? 'de.tempton.business' : 'de.tempton.app';
    return Promise.resolve(API.getCurrentStoreVesrion(platform, appName))
    .then(res => {
      let currentVersion = appVersion.split('.');
      let version = res.version.split('.');
      let old = version[1] > currentVersion[1] || (version[1] === currentVersion[1] && version[2] > currentVersion[2]);
      if (old) return Promise.reject({store_url: res.store_url});
    })
  };

  return self;
}]);
