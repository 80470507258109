import { AssignmentDataModel } from "../models/assignment.model";

window.app.factory('AssignmentFactory', ['DBFactory', 'AuthService', 'API', 'ErrFactory',
                                function (DBFactory,   AuthService,   API,   ErrFactory ) {
  class TimeTrackingAssignment {
    id:                        number;
    user_id:                   number;
    title:                     string;
    created_at:                Date;
    starts_at:                 Date;
    ends_at:                   Date;

    active:                    boolean;
    confirmed:                 boolean;
    can_release_later:         boolean;

    zip_code:                  any;
    mileage_money_time_frames: any[];

    prefillProject:            boolean;
    project:                   string;
    static COLECTION_NAME = 'time_tracking_jobs';
    constructor (data) {
      this.id                        = data.id;
      this.user_id                   = data.userId || AuthService.userId;
      this.title                     = data.title;
      this.created_at                = this.parceDate(data.created_at);
      this.starts_at                 = this.parceDate(data.starts_at);
      this.ends_at                   = this.parceDate(data.ends_at);

      this.active                    = data.active;
      this.confirmed                 = data.confirmed;
      this.can_release_later         = data.can_release_later;

      this.zip_code                  = data.zip_code
      this.mileage_money_time_frames = data.mileage_money_time_frames;

      this.prefillProject            = data.prefill_activity_with_project;
      this.project                   = data.project;
    }

    private parceDate(date: Date | string | number): Date {
      return date ? date instanceof Date ? date : new Date(date) : null;
    }

    toJSON(): AssignmentDataModel {
      return Object.assign({}, {
        id:                            this.id,
        user_id:                       this.user_id,
        title:                         this.title,

        created_at:                    this.created_at instanceof Date ? this.created_at.toISOString() : null,
        starts_at:                     this.starts_at  instanceof Date ? this.starts_at.toISOString()  : null,
        ends_at:                       this.ends_at    instanceof Date ? this.ends_at.toISOString()    : null,
  
        active:                        this.active,
        confirmed:                     this.confirmed,
        can_release_later:             this.can_release_later,

        zip_code:                      this.zip_code,
        mileage_money_time_frames:     this.mileage_money_time_frames,

        prefill_activity_with_project: this.prefillProject,
        project:                       this.project
      });
    }

    static getBetaAssignments(betaFeature: string) {
      return Promise.resolve(API.getBetaAssignments(betaFeature)).then(data => data?.assignments)
      .then(list => list.map((item) => new this(item)));
    }

    static getOwn () {
      return this.getList()
      .then((list) => list.filter((job) => job.user_id ? job.user_id === AuthService.userId : job.user_ids ? job.user_ids.find(id => id === AuthService.userId) : job._user_ids ?  job._user_ids.has(AuthService.userId) : false ));
    }

    static getList() {
      return DBFactory.then((ds) => ds.db)
      .then((db) => db.valuesByIndex(TimeTrackingAssignment.COLECTION_NAME, 'user_id', ydn.db.KeyRange.only(AuthService.userId), 2000))
      .then((list) => list.map((item) => new this(item)));
    }

    static getById(id) {
      return DBFactory.then((ds) => ds.db)
      .then((db) => db.get(TimeTrackingAssignment.COLECTION_NAME,id))
      .then((n) => { return (n)? new this(n): null });
    }

    static cleanDB () {
      return DBFactory.then((ds) => ds.db)
      .then((db) => db.clear(TimeTrackingAssignment.COLECTION_NAME));
    }

    static sync(data = null) {
      return Promise.all([
        data ? Promise.resolve(data) : API.getAssignments(),
        API.getValidMileageDays()
      ]).then((data) => {
        if (data[0]?.assignments?.length) {
          return this.mapMileageMoneyToAssignment(data[0].assignments, data[1]?.mileage_data)
          .then(assignments => Promise.all(assignments.map(assignment => this.updateAssignment(assignment).then(data => new this(data)))));
        } else return [];
      })
      .then((list) => this.cleanDB().then(() => Promise.all(list.map(i => i.save()))))
      .catch((e) => {
        if (e instanceof ErrFactory) e.notify();
        else console.error(e);
        return [];
      });
    }

    static mapMileageMoneyToAssignment(assignments, mileage_data) {
      return Promise.resolve().then(() => assignments.map((item) => {
        let mileageAvailable = mileage_data && mileage_data.find(m => m.assignment_id === item.id);
        item.mileage_money_time_frames = [];
        if (mileageAvailable) item.mileage_money_time_frames = mileageAvailable.mileage_money_time_frames;
        return item;
      }));
    }

    static updateAssignment(assignment) {
      return Promise.resolve(this.getById(assignment.id))
      .then((dbAssignment) => Object.assign({}, (dbAssignment && dbAssignment.toJSON()) || {}, assignment));
    }

    save() {
      return DBFactory.then((ds) => ds.db)
      .then((db) => db.put(TimeTrackingAssignment.COLECTION_NAME, this.toJSON()))
      .then(() => this);
    }

    remove () {
      return TimeTrackingAssignment.removeById(this.id);
    }

    static removeById (id) {
      return DBFactory.then((ds) => ds.db)
      .then((db) => db.remove(TimeTrackingAssignment.COLECTION_NAME, id))
      .catch((err) => {
        console.error(err);
        throw new ErrFactory.ErrLocalDB;
      });
    }
  }

  return TimeTrackingAssignment;
}]);
